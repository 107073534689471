
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$productdeliverystate-gap-y: $spacer * 0.4 !default;
$productdeliverystate-font-size: $font-size-sm !default;
$productdeliverystate-color: $gray-700 !default;
$productdeliverystate-line-height: 1.2 !default;

$productdeliverystate-icon-gap: $spacer * 0.6 !default;
$productdeliverystate-icon-size: $font-size-base !default;

.product-deliverystate {
  display: flex;
  align-items: center;
  margin-top: $productdeliverystate-gap-y;
  margin-bottom: $productdeliverystate-gap-y;
  font-size: $productdeliverystate-font-size;
  color: $productdeliverystate-color;
  line-height: $productdeliverystate-line-height;

  .bi {
    display: block;
    margin-top: math.div($productdeliverystate-line-height - 1, 2) * -1em;
    margin-right: $productdeliverystate-icon-gap;
    font-size: $productdeliverystate-icon-size;
  }
}

.delivery-state-popover {
  .popover-body {
    color: $productdeliverystate-color;
  }
}
