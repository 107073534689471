
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

.aspectratio {
  display: block;
  position: relative;
  width: 100%;

  &:before {
    display: block;
    content: '';
  }

  @each $key, $value in $aspect-ratios {
    &.ratio-#{$key} {
      aspect-ratio: $value;
    }
    &.ratio-#{$key}:before {
      padding-top: 100% * math.div(1, $value);
    }
  }

  > span {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
