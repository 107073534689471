
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile

$basket-emptymessage-content-text-align: center !default;

$basket-items-grid-column-gap: $grid-gutter-width * 0.5 !default;
$basket-items-grid-row-gap: $spacer * 0.4 !default;
$basket-items-grid-columns: [start] auto 4fr 2fr 2fr 2fr 1.5rem [end] !default;
$basket-items-grid-rows: min-content auto min-content !default;
$basket-items-grid-area-gap-y: $spacer !default;

$basket-items-footer-actions-gap-y: $spacer * 0.5 !default;
$basket-items-footer-actions-gap-x: $spacer * 2 !default;

// tablet

$basket-tablet: $tablet-breakpoint !default;

$basket-tablet-emptymessage-content-text-align: inherit !default;

$basket-tablet-items-grid-columns: [start] auto 4fr auto auto auto auto [end] !default;

$basket-tablet-items-header-font-size: $font-size-sm !default;
$basket-tablet-items-header-color: $dark !default;
$basket-tablet-items-header-line-height: 1.1 !default;

// desktop

$basket-desktop: $desktop-breakpoint !default;

.basket {
  @include component('y');

  .basket-emptymessage {
    @include component('y');
    margin-top: 0!important;

    .emptymessage-image {}

    .emptymessage-content {
      text-align: $basket-emptymessage-content-text-align;
    }
  }

  .basket-items {
    display: grid;
    column-gap: $basket-items-grid-column-gap;
    row-gap: $basket-items-grid-row-gap;
    grid-template-columns: $basket-items-grid-columns;
    grid-template-rows: $basket-items-grid-rows;

    [class*="area-"] {
      grid-row: auto;
    }

    .area-image,
    .area-configuration,
    .area-quantity {
      margin-bottom: $basket-items-grid-area-gap-y;
    }

    .area-separator {
      grid-row-gap: 0;
      grid-column: start / end;
      justify-self: stretch;
    }

    .area-image {
      grid-column: start / 6;
      justify-self: stretch;
    }

    .area-actions {
      grid-column: 6 / end;
      justify-self: flex-end;
    }

    .area-description {
      grid-column: start / end;
    }

    .area-configuration {
      grid-column: start / end;
    }

    .area-quantity {
      grid-column: start / end;
      justify-self: stretch;
    }

    .area-price {
      grid-column: start / end;
      justify-self: stretch;
    }

    .area-discount {
      grid-column: start / end;
      justify-self: stretch;
    }

    .area-totalprice {
      grid-column: start / end;
      justify-self: stretch;
    }

    .area-services {
      grid-column: start / end;
      justify-self: flex-end;
    }

    .items-header {
      display: none;
    }

    .items-main {
      display: contents;
    }

    .items-footer {
      display: contents;

      .area-basketactions-start {
        grid-column: start / end;
      }

      .area-basketactions-end {
        margin-top: $basket-items-footer-actions-gap-y;
        grid-column: start / end;
      }

      .footer-actions {
        margin: ($basket-items-footer-actions-gap-y * -0.5) ($basket-items-footer-actions-gap-x * -0.5);

        a {
          display: block;
          padding: ($basket-items-footer-actions-gap-y * 0.5) ($basket-items-footer-actions-gap-x * 0.5);
        }
      }
    }
  }

  .basket-payment{
    list-style: none;
    display:flex;
    padding-left:0;
    flex-wrap:wrap;

    li{
    margin-right:5px;
    }

    img{
      margin-bottom:0px;
    }
  }

  @include media-breakpoint-up($basket-tablet) {
    .basket-emptymessage {
      .emptymessage-image {}

      .emptymessage-content {
        text-align: $basket-tablet-emptymessage-content-text-align;
      }
    }

    .basket-items {
      grid-template-columns: $basket-tablet-items-grid-columns;

      .area-image,
      .area-description,
      .area-configuration,
      .area-quantity {
        margin-bottom: 0;
      }

      .area-image {
        grid-column: start;
        grid-row: auto / span 2;
      }

      .area-description {
        grid-column: 2;
      }

      .area-quantity {
        grid-column: 3;
        justify-self: center;
      }

      .area-price {
        grid-column: 4;
        justify-self: flex-end;
      }

      .area-discount {
        grid-column: 5;
        justify-self: flex-end;
      }

      .area-totalprice {
        grid-column: 6;
        justify-self: flex-end;
      }

      .area-configuration {
        grid-column: 2 / span 4;
        align-self: center;
      }

      .area-actions {
        grid-column: 6;
        align-self: flex-end;
      }

      .area-services {
        grid-column: start / end;
      }

      .items-header {
        display: contents;
        font-size: $basket-tablet-items-header-font-size;
        color: $basket-tablet-items-header-color;
        line-height: $basket-tablet-items-header-line-height;

        .area-description {
          grid-column: start / 3;
        }
      }

      .items-footer {
        .area-basketactions-start {
          grid-column: start / 4;
        }

        .area-basketactions-end {
          grid-column: 4 / end;
          justify-self: flex-end;
          margin-top: 0;
        }

        .footer-actions {
          display: flex;
        }
      }

      &.no-quantity {
        .area-price {
          grid-column: 3 / span 2;
        }
      }

      &.no-totalprice {
        .area-price {
          grid-column: 4 / span 2;
        }
      }

      &.no-quantity.no-totalprice {
        .area-price {
          grid-column: 3 / span 3;
        }
      }
    }
  }
}

@media print {
  .basket-print-mode {
    #app {
      > header,
      > footer {
        display: none!important;
      }

      > main {
        aside {
          display: none!important;
        }
      }
    }
  }
}
