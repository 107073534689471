
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

.product-properties {
  display: flex;
  align-items: center;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}
