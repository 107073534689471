@use "sass:math";

@mixin reset-sr-only() {
  position: initial;
  margin: auto;
  padding: 0;
  overflow: visible;
  width: auto;
  height: auto;
  clip: auto;
  border: initial;
  white-space: normal;
}

@mixin fullwidth-bg () {
  position: relative;

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    z-index: -1;
    width: 100vw;
    background-color: inherit;
    transform: translate3d(-50%, 0, 0);
  }
}

@mixin fullwidth-mobile () {
  margin-left: $grid-gutter-width * - 0.5;
  margin-right: $grid-gutter-width * - 0.5;

  @include media-breakpoint-up($tablet-breakpoint) {
    margin-left: 0;
    margin-right: 0;
  }
}

@mixin component ($axis: 'both', $margins: true, $paddings: ()) {
  $parameter: if($margins, 'margin', 'padding');
  $paddings: map-merge((
    mobileY: $component-padding-y,
    mobileX: $component-padding-x,
    desktopY: $component-desktop-padding-y,
    desktopX: $component-desktop-padding-x
  ), $paddings);

  @if $axis == 'y' {
    #{$parameter}-top: map-get($paddings, 'mobileY');
    #{$parameter}-bottom: map-get($paddings, 'mobileY');

    @include media-breakpoint-up($desktop-breakpoint) {
      #{$parameter}-top: map-get($paddings, 'desktopY');
      #{$parameter}-bottom: map-get($paddings, 'desktopY');
    }
  } @else if $axis == 'x' {
    #{$parameter}-left: map-get($paddings, 'mobileX');
    #{$parameter}-right: map-get($paddings, 'mobileX');

    @include media-breakpoint-up($desktop-breakpoint) {
      #{$parameter}-left: map-get($paddings, 'desktopX');
      #{$parameter}-right: map-get($paddings, 'desktopX');
    }
  } @else {
    #{$parameter}: map-get($paddings, 'mobileY') map-get($paddings, 'mobileX');

    @include media-breakpoint-up($desktop-breakpoint) {
      #{$parameter}: map-get($paddings, 'desktopY') map-get($paddings, 'desktopX');
    }
  }
}

@mixin indented-textelements ($indentations: $textelement-indentations, $selectors: $textelement-selectors, $resetters: $textelement-resetters) {
  @each $key, $value in $indentations {
    @include media-breakpoint-up($key) {
      @each $selector in $selectors {
        > #{$selector} {
          margin-left: $value * 0.5;
          margin-right: $value * 0.5;
        }
      }

      @each $resetter in $resetters {
        $parentWidthMultiplyer: math.div(100, 100 - strip-unit($value));

        > #{$resetter} {
          margin-left: $value * $parentWidthMultiplyer * -0.5;
          margin-right: $value * $parentWidthMultiplyer * -0.5;
          width: 100% * $parentWidthMultiplyer;
          max-width: none;
        }
      }
    }
  }
}

@mixin control-size ($sizeKey: null, $control-padding-y: $input-padding-y, $control-padding-x: $input-padding-x, $control-height: $input-height, $control-border-radius: $input-border-radius, $control-font-size: $input-font-size, $control-line-height: $input-line-height) {
  $size: '';

  @if $sizeKey != null {
    $size: '-#{$sizeKey}';
  }

  .col-form-label#{$size} {
    padding-top: add($control-padding-y, $input-border-width);
    padding-bottom: add($control-padding-y, $input-border-width);
    @include font-size($control-font-size);
    line-height: $control-line-height;
  }

  .form-control-plaintext {
    &.form-control#{$size} {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .form-control#{$size} {
    padding: $control-padding-y $control-padding-x;
    height: calc(#{$control-line-height * 1em} + #{$control-padding-y * 2} + #{$input-border-width * 2});
    @include font-size($control-font-size);
    line-height: $control-line-height;
    @include border-radius($control-border-radius);
  }

  .custom-select#{$size} {
    padding-top: $control-padding-y;
    padding-bottom: $control-padding-y;
    padding-left: $control-padding-x;
    height: $control-height;
    @include font-size($control-font-size);
  }

  .b-custom-control#{$size} {
    &.custom-file,
    .custom-file-input,
    .custom-file-label {
      height: $control-height;
      @include font-size($control-font-size);
    }

    .custom-file-label,
    .custom-file-label:after {
      padding: $control-padding-y $control-padding-x;
      line-height: $control-line-height;
    }

    .custom-file-label {
      @include border-radius($control-border-radius);

      &::after {
        font-size: inherit;
        height: $control-height;
        @include border-radius(0 $control-border-radius $control-border-radius 0);
      }
    }
  }

  .b-form-btn-label-control.form-control {
    > .form-control#{$size} {
      padding: $control-padding-y $control-padding-x;
      padding-left: 0!important;
      height: calc(#{$control-line-height * 1em} + #{$control-padding-y * 2});
      min-height: 0;
      @include font-size($control-font-size);
      line-height: $control-line-height;
      @include border-radius($control-border-radius);
    }
  }

  .b-form-spinbutton.form-control {
    padding: 0!important;
  }

  .input-group#{$size} > .form-control:not(textarea),
  .input-group#{$size} > .custom-select,
  .input-group#{$size} > .custom-file {
    height: calc(#{$control-line-height * 1em} + #{$control-padding-y * 2} + #{max($input-border-width, $btn-border-width) * 2});
  }

  .input-group#{$size} > .custom-file .custom-file-input,
  .input-group#{$size} > .custom-file .custom-file-label,
  .input-group#{$size} > .custom-file .custom-file-label:after,
  .input-group#{$size} > .b-form-btn-label-control.form-control > .btn,
  .input-group#{$size} > .b-form-btn-label-control.form-control > .form-control,
  .input-group#{$size} > .input-group-prepend > .btn,
  .input-group#{$size} > .input-group-append > .btn {
    height: 100%;
  }

  .input-group#{$size} > .form-control,
  .input-group#{$size} > .custom-select,
  .input-group#{$size} > .custom-file,
  .input-group#{$size} > .custom-file .custom-file-label,
  .input-group#{$size} > .custom-file .custom-file-label:after,
  .input-group#{$size} > .b-form-btn-label-control.form-control > .btn,
  .input-group#{$size} > .b-form-btn-label-control.form-control > .form-control,
  .input-group#{$size} > .input-group-prepend > .input-group-text,
  .input-group#{$size} > .input-group-append > .input-group-text,
  .input-group#{$size} > .input-group-prepend > .btn,
  .input-group#{$size} > .input-group-append > .btn {
    padding: $control-padding-y $control-padding-x;
    @include font-size($control-font-size);
    line-height: $control-line-height;
    @include border-radius($control-border-radius);
  }

  .input-group#{$size} > .custom-select {
    padding-right: $custom-select-padding-x + $custom-select-indicator-padding;
  }

  .input-group > .custom-file .custom-file-label,
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text,
  .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
  .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
  .input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
  .input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
  .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    @include border-right-radius(0);
  }

  .input-group > .custom-file .custom-file-label:after,
  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text,
  .input-group > .input-group-prepend:not(:first-child) > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    @include border-left-radius(0);
  }
}
