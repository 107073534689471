
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

.store-clerk-discount {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .discount-text{
    margin-bottom: 0;
  }

  .discount-options {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    .form-control {
      text-align: right;
    }
  }

  .read-only-discount {
    width: 100%;

    .discount-value {
      font-weight: $font-weight-bold;
    }
  }
}
