@use "sass:math";

// defines Path from app.scss to public folder

$publicPath: "../../../public/";

// defienes which breakpoint the design changes (mobile-first)

$tablet-breakpoint: md;
$desktop-breakpoint: lg;

// fonts

$base-font-size: 16px;
$base-desktop-font-size: $base-font-size;

// common

$borders: (
  base: $border-width solid $border-color,
  bold: 2px solid $border-color,
  bright: 2px solid $white,
  header: 3px solid $gray-200,
  footer: $border-width solid $brown
);

$shadows: (
  header: 0px 8px 18px rgba($black, 0.05),
  image: 0px 16px 50px rgba($brown, 0.3),
  dropdown: 0px 8px 18px rgba($black, 0.1),
  herobanner-text: 0px 10px 40px rgba($black, 0.6),
  herobanner-carousel: 0px 20px 25px rgba($black, 0.1),
  carousel-text: 0px 10px 40px rgba($black, 0.6),
  salebutton: 0px 4px 6px rgba($black, 0.08),
  productteaser: 0 10px 30px rgba($brown, 0.2),
  component: 0px 8px 15px rgba($black, 0.05),
  cookiebanner: 0px -8px 15px rgba($black, 0.05)
);

$aspect-ratios: (
  3x4: math.div(3, 4),
  1x1: 1,
  4x3: math.div(4, 3),
  2x1: math.div(2, 1)
);

// layout

$header-collapse-transition-ease-in: linear;
$header-collapse-transition-ease-out: linear;
$header-collapse-transition-speed-in: 200ms; // dont go over 300ms or change constant MIXIN_VIEWPORT_THROTTLE_TIME
$header-collapse-transition-speed-out: 200ms; // dont go over 300ms or change constant MIXIN_VIEWPORT_THROTTLE_TIME

$layout-minimal-gap: $spacer * 3;
$layout-minimal-bg: $beige;
$layout-minimal-container-widths: map-merge(
  $container-max-widths,
  (
    xl: 1100px
  )
);
$layout-minimal-content-bg: $white;
$layout-minimal-content-box-shadow: map-get($shadows, "component");

$layout-minimal-content-header-gap: $spacer * 1.5;

// custom-components

// components
$component-padding-y: $spacer * 2;
$component-padding-x: $spacer * 1.5;

$component-desktop-padding-y: $spacer * 4;
$component-desktop-padding-x: $spacer * 3;

// productdata-header
$productdataheader-gap: $component-padding-y;
$productdataheader-productdataheader-gap: 2px;
$productdataheader-padding-y: $grid-gutter-width * 0.5;
$productdataheader-padding-x: $productdataheader-padding-y;
$productdataheader-bg: $beige;
$productdataheader-border-radius: $border-radius-sm;

$productdataheader-desktop-gap: $component-desktop-padding-y;
$productdataheader-desktop-productdataheader-gap: $productdataheader-productdataheader-gap;
$productdataheader-desktop-padding-y: $component-padding-y;
$productdataheader-desktop-padding-x: $component-padding-x;
$productdataheader-desktop-border-radius: $productdataheader-border-radius;

// productdata
$productdata-gap: $productdataheader-gap;
$productdata-productdata-gap: $productdataheader-productdataheader-gap;
$productdata-padding-y: $grid-gutter-width * 0.4;
$productdata-padding-x: $productdataheader-padding-x;
$productdata-bg: $productdataheader-bg;
$productdata-border-radius: $productdataheader-border-radius;

$productdata-desktop-gap: $productdataheader-desktop-gap;
$productdata-desktop-productdata-gap: $productdataheader-desktop-productdataheader-gap;
$productdata-desktop-padding-y: $productdataheader-desktop-padding-y;
$productdata-desktop-padding-x: $productdataheader-desktop-padding-x;
$productdata-desktop-border-radius: $productdataheader-desktop-border-radius;

// module-extensions

$badge-group-font-size: $badge-font-size;
$badge-group-badge-margin: $spacer * 0.1;

$btn-list-btn-margin: $spacer * 0.2;

$btn-hover-darken-percentage: 5.1%;
$btn-active-darken-percentage: 9.725%;

$dropdown-toggle-color: $dropdown-color;
$dropdown-toggle-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$dropdown-toggle-color}' width='16' height='16' viewBox='0 0 16 16'><path d='M11.347,6.188c-0.199-0.197-0.521-0.197-0.721,0L8,8.815L5.374,6.188c-0.199-0.197-0.521-0.197-0.721,0 c-0.198,0.199-0.198,0.521,0,0.721l2.849,2.848c0.137,0.136,0.316,0.203,0.497,0.203c0.182,0,0.362-0.068,0.502-0.205l2.846-2.846 C11.545,6.709,11.545,6.387,11.347,6.188z'/></svg>");
$dropdown-toggle-icon-size: 1.2em;
$dropdown-item-active-gap: $spacer * 0.2;
$dropdown-item-active-color: $dropdown-link-active-color;
$dropdown-item-active-icon: url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' fill='#{$dropdown-item-active-color}' width='10' height='10' viewBox='0 0 16 16'><path d='M15,0.9c-0.2-0.1-0.5-0.1-0.6,0.1L5.3,13.9c-0.3,0.4-0.8,0.5-1.2,0.2C4,14.1,4,14,3.9,14l-2.3-3.1c-0.1-0.2-0.4-0.2-0.6-0.1c-0.2,0.1-0.2,0.4-0.1,0.6l2.3,3.1c0.1,0.2,0.3,0.3,0.4,0.4c0.3,0.2,0.7,0.3,1,0.3c0.6,0,1.1-0.3,1.5-0.8l9.1-12.9C15.3,1.3,15.2,1,15,0.9z'/></svg>");
$dropdown-item-active-icon-size: 1em;

$custom-control-disabled-opacity: 0.5;

$custom-switch-bg: $light;
$custom-switch-on-bg: $success;
$custom-switch-on-border-color: $custom-switch-on-bg;

// modules

$blockquote-padding-y: $spacer * 4;
$blockquote-padding-x: 7.5vw;

$blockquote-border-width: 40%;
$blockquote-border-size: $border-width;
$blockquote-border-color: $border-color;

$blockquote-quote-start: "«";
$blockquote-quote-end: "»";
$blockquote-font-family: $font-family-base;
$blockquote-font-size: $h4-font-size;
$blockquote-font-weight: $font-weight-light;
$blockquote-font-style: normal;
$blockquote-line-height: $line-height-base;
$blockquote-text-align: center;

$blockquote-cite-gap: $spacer;
$blockquote-cite-font-family: $blockquote-font-family;
$blockquote-cite-font-size: $font-size-base;
$blockquote-cite-font-weight: $font-weight-medium;
$blockquote-cite-font-style: normal;

$textelement-indentations: (
  xs: 15%,
  sm: 20%,
  md: 20%,
  lg: 25%,
  xl: 30%
);

$textelement-selectors: (
  "p",
  "span",
  "br",
  "h1",
  ".h1",
  "h2",
  ".h2",
  "h3",
  ".h3",
  "h4",
  ".h4",
  "h5",
  ".h5",
  "h6",
  ".h6",
  "strong",
  "em",
  "blockquote",
  "q",
  "hr",
  "code",
  "pre",
  "ul",
  "ol",
  "dl",
  "mark",
  "ins",
  "del",
  "sup",
  "sub",
  "small",
  "i",
  "b",
  "div.icon-tile-grid.default",
  ".lead"
);

$textelement-resetters: ("p > img");
