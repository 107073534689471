
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$loader-padding-y: $component-padding-y !default;
$loader-padding-x: $component-padding-y !default;

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $loader-padding-y $loader-padding-x;

  &.is-inline {
    display: inline-block;
    padding: 0;
    margin-top: -0.1em;
    vertical-align: middle;

    > span {
      display: block;
      width: 1.2em;
      height: 1.2em;
      vertical-align: middle;
    }
  }
}
