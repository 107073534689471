
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile

$basketitem-separator-gap-y: $spacer * 0.4 !default;
$basketitem-separator-border: map-get($borders, 'bold') !default;

$basketitem-image-width: 120px !default;
$basketitem-img-fallback-size: 100% !default;
$basketitem-img-fallback-color: $imgfallback !default;

$basketitem-description-name-font-weight: $font-weight-bold !default;
$basketitem-description-name-text-transform: uppercase !default;

$basketitem-configuration-gap-y: 0 !default;
$basketitem-configuration-gap-x: $spacer * 1.2 !default;

$basketitem-totalprice-font-weight: $font-weight-bold !default;

$basketitem-actions-gap-y: $spacer * 1 !default;
$basketitem-actions-gap-x: $basketitem-actions-gap-y !default;
$basketitem-actions-font-size: $font-size-base * 1.25 !default;

// tablet

$basketitem-tablet: $tablet-breakpoint !default;

$basketitem-tablet-image-width: 100px !default;

$basketitem-tablet-actions-gap-y: $spacer * 0.4 !default;
$basketitem-tablet-actions-gap-x: $spacer * 1.5 !default;
$basketitem-tablet-actions-font-size: 1rem !default;

.basket {
  .basket-items {
    .basket-item {
      display: contents;

      .area-separator {
        margin-top: $basketitem-separator-gap-y;
        margin-bottom: $basketitem-separator-gap-y;
        border-top: $basketitem-separator-border;

        &.half {
          border-top-width: 1px;
        }
      }

      .area-image {
        a {
          display: block;
          color: inherit;
          text-decoration: none;

          img,
          .is-fallback-image {
            display: block;
            width: $basketitem-image-width;
            max-width: none;

            .bi {
              display: block;
              margin: auto;
              width: $basketitem-img-fallback-size;
              height: $basketitem-img-fallback-size;
              color: $basketitem-img-fallback-color;
            }
          }
        }
      }

      .area-description {
        a {
          display: block;
          color: inherit;
          text-decoration: none;

          .item-name {
            font-weight: $basketitem-description-name-font-weight;
            text-transform: $basketitem-description-name-text-transform;
          }
        }
      }

      .area-configuration {
        .item-configuration {
          display: flex;
          flex-wrap: wrap;
          margin: ($basketitem-configuration-gap-y * -0.5) ($basketitem-configuration-gap-x * -0.5);

          span {
            display: block;
            padding: ($basketitem-configuration-gap-y * 0.5) ($basketitem-configuration-gap-x * 0.5);
          }
        }

        .store-clerk-actions {
          display: flex;
          flex-wrap: wrap;
        }
      }

      .area-actions {
        .item-actions {
          display: flex;
          flex-direction: column-reverse;
          margin: ($basketitem-actions-gap-y * -0.5) ($basketitem-actions-gap-x * -0.5);

          a {
            display: block;
            padding: ($basketitem-actions-gap-y * 0.5) ($basketitem-actions-gap-x * 0.5);
            font-size: $basketitem-actions-font-size;
          }
        }
      }

      .area-store-clerk-discount-options {
        grid-column: 2/span 5;
        grid-row: auto;
      }

      .area-totalprice {
        font-weight: $basketitem-totalprice-font-weight;
      }

      .area-still-required-products {
        grid-column: 1 / span 6;
        grid-row: auto;
      }

      + .basket-item {
        .area-separator {
          &:first-child {
            display: none;
          }
        }
      }

      @include media-breakpoint-up($basketitem-tablet) {
        .area-image {
          a {

            img,
            .is-fallback-image {
              margin: 0;
              width: $basketitem-tablet-image-width;
            }
          }
        }

        .area-price,
        .area-totalprice {
          white-space: nowrap;
        }

        .area-actions {
          .item-actions {
            flex-direction: row;
            flex-wrap: wrap;
            margin: ($basketitem-tablet-actions-gap-y * -0.5) ($basketitem-tablet-actions-gap-x * -0.5);

            a {
              padding: ($basketitem-tablet-actions-gap-y * 0.5) ($basketitem-tablet-actions-gap-x * 0.5);
              font-size: $basketitem-tablet-actions-font-size;
            }
          }
        }

        .area-still-required-products {
          grid-column: 2 / span 5;
          grid-row: auto;
        }
      }
    }
  }
}
