
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$summaryprice-total-margin-y: $spacer * 0.5 !default;
$summaryprice-total-border: set-nth(map-get($borders, 'bold'), 3, $hr-border-color) !default;
$summaryprice-total-font-weight: $font-weight-bold !default;

.summary-price {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  dt,
  dd {
    margin: 0;
    font-weight: inherit;
  }

  &.is-total {
    margin-top: $summaryprice-total-margin-y;
    padding-top: $summaryprice-total-margin-y;
    border-top: $summaryprice-total-border;
    font-weight: $summaryprice-total-font-weight;
  }
}
