
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$summary-separator-margin-y: $spacer * 0.5 !default;

.summary-separator {
  hr {
    margin-top: $summary-separator-margin-y;
    margin-bottom: $summary-separator-margin-y;
  }
}
